import style from "components/CountryQuery.module.scss";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React from "react";
import { X } from "react-feather";
const CountryQuery = () => {
  const { countryStore } = useStores();

  const handleChange = (ev) => {
    countryStore.setQuery(ev.target.value);
  };

  const handleClick = () => {
    countryStore.setQuery("");
  };

  return (
    <div className={style.root}>
      <input
        className={style.input}
        value={countryStore.query}
        name="query"
        onChange={handleChange}
        placeholder="Filter..."
      />
      {!!countryStore.query && (
        <button
          className={style.clearButton}
          onClick={handleClick}
          type="button"
        >
          <X size={16} strokeWidth={1.5} />
        </button>
      )}
    </div>
  );
};

CountryQuery.displayName = "CountryQuery";

export default observer(CountryQuery);
