import style from "components/Settings.module.scss";
import ToggleButtons from "components/ToggleButtons";
import VersionSelector from "components/VersionSelector";
import { observer } from "mobx-react";
import React from "react";

const Settings = () => {
  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.toggleButtons}>
          <ToggleButtons />
        </div>
        <div className={style.versionSelector}>
          <VersionSelector />
        </div>
      </div>
    </div>
  );
};

Settings.displayName = "Settings";

export default observer(Settings);
