import "index.css";

import App from "components/App";
import { configure } from "mobx";
import React from "react";
import ReactDOM from "react-dom";

configure({ enforceActions: "observed" });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
