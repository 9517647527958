import classNames from "classnames";
import CategoryIndicatorRanks from "components/CategoryIndicatorRanks";
import CategoryRanks from "components/CategoryRanks";
import style from "components/Country.module.scss";
import IndicatorRanks from "components/IndicatorRanks";
import Rank from "components/Rank";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React, { useLayoutEffect, useRef } from "react";
import { ChevronDown, ChevronUp } from "react-feather";

let lastScrollX = 0;
let scrolling = false;

const Country = ({ country }) => {
  const { categoryStore, countryStore } = useStores();

  const activeCountryCategory =
    categoryStore.activeCategory &&
    country.categories.find((c) => c.id === categoryStore.activeCategory.id);

  const isActiveCountry =
    countryStore.activeCountry &&
    countryStore.activeCountry.iso === country.iso;

  const handleActiveToggleClick = () => {
    categoryStore.setActiveCategory(null);
    if (isActiveCountry) {
      countryStore.setActiveCountry(null);
    } else {
      countryStore.setActiveCountry(country);
    }
  };

  const showCategoryRanks =
    !categoryStore.activeCategory ||
    !!(categoryStore.activeCategory && countryStore.activeCountry);

  const showIndicatorRanks = isActiveCountry && !categoryStore.activeCategory;

  const showCategoryIndicatorRanks =
    !!(countryStore.activeCountry && isActiveCountry) ||
    !!(!countryStore.activeCountry && categoryStore.activeCategory);

  const className = classNames(style.root, {
    [style.root___isActive]: isActiveCountry,
  });

  const ref = useRef(null);

  useLayoutEffect(() => {
    const element = ref.current;

    const handleScroll = (ev) => {
      lastScrollX = ev.target.scrollLeft;

      if (!scrolling) {
        window.requestAnimationFrame(() => {
          categoryStore.setScrollX(lastScrollX);
          scrolling = false;
        });

        scrolling = true;
      }
    };

    element.addEventListener("scroll", handleScroll);

    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, [categoryStore]);

  useLayoutEffect(() => {
    const setScrollLeft = () => (ref.current.scrollLeft = countryStore.scrollX);

    if (!scrolling && ref.current) setScrollLeft();
  }, [countryStore.scrollX]);

  return (
    <div className={className}>
      <div className={style.header}>
        <button
          className={style.toggleButton}
          onClick={handleActiveToggleClick}
          type="button"
        >
          <div className={style.rank}>
            <Rank
              isActive={countryStore.orderProperty === "rank"}
              label={country.rank}
            />
          </div>

          {activeCountryCategory && (
            <div className={style.categoryRank}>
              <Rank
                isActive
                category={categoryStore.activeCategory}
                label={activeCountryCategory.rank}
              />
            </div>
          )}
          <div className={style.name}>{country.name}</div>

          <div className={style.icon}>
            {isActiveCountry ? (
              <ChevronUp size={18} strokeWidth={1.5} />
            ) : (
              <ChevronDown size={18} strokeWidth={1.5} />
            )}
          </div>
        </button>
      </div>
      <div className={style.content} ref={ref}>
        {showCategoryRanks && (
          <div className={style.categoryRanks}>
            <CategoryRanks country={country} />
          </div>
        )}

        {showIndicatorRanks && (
          <div className={style.activeCountryCategories}>
            <IndicatorRanks country={country} />
          </div>
        )}

        {showCategoryIndicatorRanks && (
          <div className={style.activeCategoryIndicators}>
            <CategoryIndicatorRanks
              country={country}
              category={activeCountryCategory}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Country.displayName = "Country";

export default observer(Country);
