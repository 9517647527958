/* eslint-disable react/prop-types */
import classNames from "classnames";
import style from "components/Category.module.scss";
import EnableToggle from "components/EnableToggle";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React from "react";

const Category = ({ category }) => {
  const { countryStore, categoryStore } = useStores();

  const isActive =
    categoryStore.activeCategory &&
    categoryStore.activeCategory.id === category.id;

  const handleActiveToggleClick = () => {
    countryStore.setActiveCountry(null);
    if (categoryStore.activeCategory === category) {
      categoryStore.setActiveCategory(null);
    } else {
      category.setIsEnabled(true);
      categoryStore.setActiveCategory(category);
    }
  };

  const className = classNames(style.root, style[`root___${category.id}`], {
    [style.root___isEnabled]: category.isEnabled,
    [style.root___isDisabled]: !category.isEnabled,
    [style.root___isActive]: !!categoryStore.activeCategory && isActive,
    [style.root___isInactive]: categoryStore.activeCategory && !isActive,
  });

  return (
    <div key={category.id} className={className}>
      <button
        className={style.activeToggle}
        onClick={handleActiveToggleClick}
        type="button"
      >
        <div className={style.label}>{category.name}</div>
        <div className={style.subLabel}>Global Contribution</div>
      </button>

      <div className={style.enableToggle}>
        <EnableToggle
          category={category}
          isDisabled={categoryStore.activeCategory}
        />
      </div>
    </div>
  );
};

Category.displayName = "Category";

export default observer(Category);
