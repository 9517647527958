import includes from "lodash/includes";
import pickBy from "lodash/pickBy";
import toLower from "lodash/toLower";
import { makeAutoObservable } from "mobx";

class Country {
  constructor({ attributes, store }) {
    makeAutoObservable(this);

    this.store = store;
    this.set(attributes);
  }

  categories = [];

  name = "";

  iso = "";

  lowestRank = 0;

  missingValues = 0;

  rankMean = 0;

  rank = 0;

  rankSum = 0;

  matches(query) {
    return (
      includes(toLower(this.name), toLower(query)) ||
      includes(toLower(this.iso), toLower(query))
    );
  }

  getCategory(category) {
    return this.categories.find((cat) => cat.id === category.id);
  }

  getCategoryRank(category) {
    return this.getCategory(category).rank;
  }

  set = (attributes) => {
    const pickedAttributes = pickBy(attributes, (prop) => prop !== undefined);

    Object.assign(this, pickedAttributes);
    return this;
  };
}

export default Country;
