import classNames from "classnames";
import style from "components/Rank.module.scss";
import { observer } from "mobx-react";
import React from "react";

const Rank = ({ isActive, category, label }) => {
  const className = classNames(style.root, {
    [style.root___isActive]: isActive,
    [style[`root___${category.id}`]]: category,
  });

  return (
    <div className={className}>
      <div className={style.label}>{label}</div>
    </div>
  );
};

Rank.defaultProps = {
  isActive: false,
  category: {},
  label: "",
};

Rank.displayName = "Rank";

export default observer(Rank);
