import classNames from "classnames";
import style from "components/VersionSelector.module.scss";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React from "react";

const VersionSelector = () => {
  const { countryStore } = useStores();

  return (
    <div className={style.root}>
      <div className={style.label}>Version</div>
      <div className={style.buttons}>
        {countryStore.versions.map((version) => (
          <Button key={version} version={version} />
        ))}
      </div>
    </div>
  );
};

const Button = observer(({ version }) => {
  const { countryStore } = useStores();

  const handleClick = () => {
    countryStore.setVersion(version);
    countryStore.set();
  };

  const isActive = countryStore.version === version;

  const className = classNames(style.button, {
    [style.button___isActive]: isActive,
  });

  return (
    <button className={className} onClick={handleClick} type="button">
      {version}
    </button>
  );
});

VersionSelector.displayName = "VersionSelector";

export default observer(VersionSelector);
