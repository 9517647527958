import "styles/tippy.scss";

import style from "components/MobileNavigation.module.scss";
import { ITEMS } from "lib/nav";
import React, { useState } from "react";
import { Menu, X } from "react-feather";

import Tippy from "@tippyjs/react";

const MobileNavigation = () => {
  const [visible, setVisible] = useState(false);

  const hide = () => setVisible(false);
  const toggle = () => setVisible(!visible);

  const Content = () => {
    return (
      <div className={style.content}>
        <div className={style.items}>
          {ITEMS.map((item) => {
            return (
              <a
                key={item.label}
                href={item.href}
                className={style.item}
                target="_blank"
                rel="noreferrer"
              >
                <div className={style.label}>{item.label}</div>
              </a>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={style.root}>
      <Tippy
        content={<Content />}
        theme="light-border"
        interactive
        appendTo={() => document.body}
        visible={visible}
        offset={[0, -3]}
        onClickOutside={hide}
      >
        <button className={style.button} type="button" onClick={() => toggle()}>
          {visible && <X strokeWidth={1} size={20} />}
          {!visible && <Menu strokeWidth={1} size={20} />}
        </button>
      </Tippy>
    </div>
  );
};

export default MobileNavigation;
