import style from "components/IndicatorInfo.module.scss";
import React from "react";

const IndicatorInfo = ({ indicator }) => {
  return (
    <div className={style.root}>
      <div>
        <div className={style.heading}>{indicator.name}</div>
        <div className={style.description}>{indicator.description}</div>

        <div className={style.subHeading}>Reference</div>
        <div className={style.reference}>{indicator.reference}</div>

        <div className={style.subHeading}>Treatment</div>
        <div className={style.treatment}>{indicator.treatment}</div>
      </div>
    </div>
  );
};

export default IndicatorInfo;
