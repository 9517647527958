import style from "components/ToggleButton.module.scss";
import React from "react";
import { X } from "react-feather";

const ToggleButton = ({ label, onClick }) => {
  return (
    <button className={style.root} onClick={onClick} type="button">
      <div className={style.label}>{label}</div>
      <div className={style.icon}>
        <X size={13} strokeWidth={1.5} />
      </div>
    </button>
  );
};

ToggleButton.defaultProps = {
  label: null,
  onClick: () => null,
};

export default ToggleButton;
