import classNames from "classnames";
import style from "components/CategoriesToggle.module.scss";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React from "react";
import { ChevronDown, ChevronUp } from "react-feather";

const CategoriesToggle = () => {
  const { uiStore } = useStores();

  const handleClick = () => {
    uiStore.toggleIsCategoriesActive();
  };

  const className = classNames(style.root, {
    [style.root___isActive]: uiStore.isCategoriesActive,
  });

  return (
    <div className={className}>
      <button className={style.button} type="button" onClick={handleClick}>
        <div className={style.label}>
          {uiStore.isCategoriesActive ? "Hide Indicators" : "Show Indicators"}
        </div>
        <div className={style.icon}>
          {uiStore.isCategoriesActive ? (
            <ChevronUp size={18} strokeWidth={1.5} />
          ) : (
            <ChevronDown size={18} strokeWidth={1.5} />
          )}
        </div>
      </button>
    </div>
  );
};

export default observer(CategoriesToggle);
