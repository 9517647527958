import classNames from "classnames";
import style from "components/EnableToggle.module.scss";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React from "react";
import { Check, X } from "react-feather";

import Tippy from "@tippyjs/react";

const EnableToggle = ({ category, isDisabled = false }) => {
  const { countryStore, categoryStore } = useStores();

  const handleEnableToggleClick = () => {
    countryStore.setActiveCountry(null);
    category.setIsEnabled(!category.isEnabled);
    if (categoryStore.enabledCount >= 1) {
      countryStore.calculateRanks();
    }
  };

  const className = classNames(style.root, style[`root___${category.id}`], {
    [style.root___isActive]: category.isEnabled,
    [style.root___isInactive]: !category.isEnabled,
    [style.root___isDisabled]: isDisabled,
  });

  const TooltipContent = () => {
    return (
      <div className={style.content}>
        Click to{" "}
        <strong>{category.isEnabled ? "exclude from" : "include in"}</strong>{" "}
        the overall ranking
      </div>
    );
  };

  return (
    <Tippy
      content={<TooltipContent />}
      theme="light-border"
      placement="bottom"
      delay={250}
      appendTo={() => document.body}
    >
      <button
        className={className}
        onClick={handleEnableToggleClick}
        type="button"
        disabled={!!categoryStore.activeCategory}
      >
        <div className={style.indicator}>
          <div className={style.icon}>
            {category.isEnabled ? <Check size={12} /> : <X size={12} />}
          </div>
        </div>
      </button>
    </Tippy>
  );
};

export default observer(EnableToggle);
