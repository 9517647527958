import classNames from "classnames";
import style from "components/IndicatorScale.module.scss";
import { motion } from "framer-motion";
import { useStores } from "hooks/useStores";
import { easeOutQuart } from "lib/easings";
import { observer } from "mobx-react";
import React from "react";
import { Minus, Plus, X } from "react-feather";

import Tippy from "@tippyjs/react";

const IndicatorScale = ({ country, category, ranking, indicator }) => {
  const { categoryStore } = useStores();

  const barWidth = Math.floor(Math.abs(ranking - 0.5) * 100);
  const barLeft = ranking <= 0.5 ? 50 : 50 - barWidth;

  const isMissing = ranking.toString() === "0.0000";

  const categoryModel = categoryStore.get(category.id);

  let rankingRelative = "";

  if (ranking >= 0 && ranking <= 0.2) {
    rankingRelative = "much more good than";
  } else if (ranking > 0.2 && ranking <= 0.4) {
    rankingRelative = "more good than";
  } else if (ranking > 0.4 && ranking <= 0.6) {
    rankingRelative = "about";
  } else if (ranking > 0.6 && ranking <= 0.8) {
    rankingRelative = "less good than";
  } else if (ranking > 0.8 && ranking <= 1) {
    rankingRelative = "less good than";
  }

  const TooltipContent = () => {
    if (isMissing) {
      return (
        <div
          className={style.content}
        >{`No data is available for ${country.name}'s contribution to ${categoryModel.name}.`}</div>
      );
    }

    if (!isMissing) {
      return (
        <div
          className={style.content}
        >{`${country.name} contributes ${rankingRelative} average to ${categoryModel.name} through ${indicator.name}`}</div>
      );
    }
  };

  const className = classNames(style.root, style[`root___${category.id}`]);

  return (
    <div className={className}>
      <Tippy
        content={<TooltipContent />}
        theme="light-border"
        interactive
        placement="bottom"
        appendTo={() => document.body}
      >
        <div className={style.base}>
          <div className={style.mid} />

          {ranking > 0 && (
            <motion.div
              className={style.bar}
              initial={{ width: "0%", left: "50%" }}
              animate={{ width: `${barWidth}%`, left: `${barLeft}%` }}
              transition={{
                duration: 0.5,
                ease: easeOutQuart,
              }}
            />
          )}

          <>
            {!isMissing && (
              <motion.div
                className={style.ball}
                initial={{ left: "50%" }}
                animate={{ left: `${(1 - ranking) * 100}%` }}
                transition={{
                  duration: 0.5,
                  ease: easeOutQuart,
                }}
              >
                {ranking >= 0.5 ? (
                  <Minus size={13} strokeWidth={1.5} />
                ) : (
                  <Plus size={13} strokeWidth={1.5} />
                )}
              </motion.div>
            )}
            {isMissing && (
              <motion.div
                className={style.ball}
                initial={{ opacity: 0 }}
                animate={{ left: `50%`, opacity: 1 }}
                transition={{
                  duration: 0.5,
                  ease: easeOutQuart,
                }}
              >
                <X size={13} strokeWidth={1.5} />
              </motion.div>
            )}
          </>
        </div>
      </Tippy>
    </div>
  );
};

export default observer(IndicatorScale);
