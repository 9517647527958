import "styles/tippy.scss";

import style from "components/Popover.module.scss";
import React from "react";

import Tippy from "@tippyjs/react";

const Popover = ({ content }) => {
  return (
    <div className={style.root}>
      <Tippy
        content={content}
        theme="light-border"
        interactive
        appendTo={() => document.body}
      >
        <button className={style.button} type="button">
          ?
        </button>
      </Tippy>
    </div>
  );
};

export default Popover;
