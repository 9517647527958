import classNames from "classnames";
import style from "components/CategoryIndicatorRanks.module.scss";
import IndicatorInfo from "components/IndicatorInfo";
import IndicatorScale from "components/IndicatorScale";
import Popover from "components/Popover";
import { useStores } from "hooks/useStores";
import orderBy from "lodash/orderBy";
import { observer } from "mobx-react";
import React from "react";

const CategoryIndicatorRanks = ({ country, category }) => {
  const { categoryStore, countryStore } = useStores();

  if (!category) return null;

  // const showLabel = !!countryStore.activeCountry;

  const orderedIndicators = orderBy(category.indicators, "id");

  const className = classNames(style.root, style[`root___${category.id}`], {
    [style.root___country]:
      countryStore.activeCountry && !categoryStore.activeCategory,
    [style.root___overall]: !countryStore.activeCountry,
  });

  return (
    <div className={className}>
      {orderedIndicators.map((indicator) => {
        const versionIndicator = categoryStore.getIndicator(
          category.id,
          indicator.id,
          countryStore.version
        );

        return (
          <div key={indicator.id} className={style.indicatorRank}>
            {!!countryStore.activeCountry && (
              <div className={style.header}>
                <div className={style.label}>{versionIndicator.name}</div>
                {countryStore.activeCountry && (
                  <div className={style.popover}>
                    <Popover
                      content={<IndicatorInfo indicator={versionIndicator} />}
                    />
                  </div>
                )}
              </div>
            )}
            <div className={style.indicatorScale}>
              <IndicatorScale
                indicator={versionIndicator}
                country={country}
                category={category}
                ranking={indicator.ranking}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

CategoryIndicatorRanks.displayName = "CategoryIndicatorRanks";

export default observer(CategoryIndicatorRanks);
