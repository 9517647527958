import style from "components/Categories.module.scss";
import Category from "components/Category";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React, { useLayoutEffect, useRef } from "react";

let lastScrollX = 0;
let scrolling = false;

const Categories = () => {
  const { categoryStore, countryStore } = useStores();

  const ref = useRef(null);

  useLayoutEffect(() => {
    const element = ref.current;

    const handleScroll = (ev) => {
      lastScrollX = ev.target.scrollLeft;

      if (!scrolling) {
        window.requestAnimationFrame(() => {
          countryStore.setScrollX(lastScrollX);
          scrolling = false;
        });

        scrolling = true;
      }
    };

    element.addEventListener("scroll", handleScroll);

    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, [countryStore]);

  useLayoutEffect(() => {
    const setScrollLeft = () =>
      (ref.current.scrollLeft = categoryStore.scrollX);
    if (!scrolling && ref.current) setScrollLeft();
  }, [categoryStore.scrollX]);

  return (
    <div className={style.root}>
      <div className={style.categories} ref={ref}>
        {categoryStore.categories.map((category) => (
          <div key={category.id} className={style.category}>
            <Category key={category.id} category={category} />
          </div>
        ))}
      </div>
    </div>
  );
};

Categories.displayName = "Categories";

export default observer(Categories);
