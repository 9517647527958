import React from "react";
import CategoryStore from "stores/CategoryStore";
import CountryStore from "stores/CountryStore";
import UIStore from "stores/UIStore";

const uiStore = new UIStore();
const categoryStore = new CategoryStore();
const countryStore = new CountryStore({ categoryStore });

const stores = {
  countryStore,
  categoryStore,
  uiStore,
};

export const storesContext = React.createContext(stores);

export default storesContext;
