import CategoryIndicatorRanks from "components/CategoryIndicatorRanks";
import style from "components/IndicatorRanks.module.scss";
import { observer } from "mobx-react";
import React from "react";

const IndicatorRanks = ({ country }) => {
  if (!country) return null;

  return (
    <div className={style.root}>
      {country.categories.map((category) => {
        return (
          <div key={category.id} className={style.category}>
            <CategoryIndicatorRanks country={country} category={category} />
          </div>
        );
      })}
    </div>
  );
};

IndicatorRanks.displayName = "IndicatorRanks";

export default observer(IndicatorRanks);
