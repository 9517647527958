import debounce from "lodash/debounce";
import { makeAutoObservable } from "mobx";
import { enableStaticRendering } from "mobx-react";

enableStaticRendering(typeof window === "undefined");

class UIStore {
  constructor() {
    makeAutoObservable(this);

    this.setWindow();
  }

  /*
		Window
	*/

  windowDimensions = {
    width: 0,
    height: 0,
  };

  get windowHeight() {
    return this.windowDimensions.height;
  }

  get windowWidth() {
    return this.windowDimensions.width;
  }

  setWindowDimensions = () => {
    if (typeof window === "object") {
      this.windowDimensions = {
        width:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
        height:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight,
      };
    }
  };

  handleWindowSizeChange = debounce(() => {
    this.setWindowDimensions();
  }, 100);

  setWindow = () => {
    if (typeof window === "object") {
      this.setWindowDimensions();
      window.addEventListener("resize", this.handleWindowSizeChange);
    }
  };

  get isSmallerThanTablet() {
    return this.windowWidth < 768;
  }

  get isSmallerThanDesktop() {
    return this.windowWidth < 1024;
  }

  /*
	Categories
	*/

  isCategoriesActive = false;

  setIsCategoriesActive = (value) => {
    this.isCategoriesActive = value;
  };

  toggleIsCategoriesActive = () => {
    this.isCategoriesActive = !this.isCategoriesActive;
  };
}

export default UIStore;
