import style from "components/App.module.scss";
import Categories from "components/Categories";
import CategoriesToggle from "components/CategoriesToggle";
import Countries from "components/Countries";
import CountryQuery from "components/CountryQuery";
import Indicators from "components/Indicators";
import MobileNavigation from "components/MobileNavigation";
import Navigation from "components/Navigation";
import Settings from "components/Settings";
import SortControls from "components/SortControls";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React, { useEffect } from "react";

const App = () => {
  const { countryStore, categoryStore, uiStore } = useStores();

  useEffect(() => {
    categoryStore.set();
  }, [categoryStore]);

  useEffect(() => {
    if (categoryStore.categories.length > 0) countryStore.set();
  }, [categoryStore, categoryStore.categories.length, countryStore]);

  // If there is no activeCategory, rank property needs to be rank
  useEffect(() => {
    if (
      countryStore.orderProperty === "categoryRank" &&
      !categoryStore.activeCategory
    )
      countryStore.setOrderProperty("rank");
  }, [countryStore, countryStore.orderProperty, categoryStore.activeCategory]);

  if (!categoryStore.categories) return null;

  // Show indictaors only if an activeCategory is shown without an active country
  const showIndicators =
    categoryStore.activeCategory && !countryStore.activeCountry;

  const showCategories =
    !uiStore.isSmallerThanTablet ||
    (uiStore.isSmallerThanTablet && uiStore.isCategoriesActive);

  return (
    <div className={style.root}>
      <header className={style.header}>
        <div className={style.sub}>
          <div className={style.logoContainer}>
            <div className={style.logo}>
              <a
                href="https://www.goodcountry.org"
                className={style.logoMain}
                target="_blank"
                rel="noopener noreferrer"
              >
                The Good Country
              </a>
              <span className={style.logoSub}>Index</span>
            </div>
          </div>
          <div className={style.navigation}>
            <Navigation />
          </div>
          <div className={style.mobileNavigation}>
            <MobileNavigation />
          </div>
          <div className={style.settings}>
            <Settings />
          </div>
        </div>
        <div className={style.main}>
          <div className={style.tools}>
            <div className={style.sortControls}>
              <SortControls />
            </div>
            <div className={style.countryQuery}>
              <CountryQuery />
            </div>
          </div>

          <div className={style.categoriesToggle}>
            <CategoriesToggle />
          </div>
          {showCategories && (
            <div className={style.categories}>
              <Categories />
            </div>
          )}
        </div>
        {showIndicators && (
          <div className={style.indicators}>
            <Indicators />
          </div>
        )}
      </header>
      <div className={style.countries}>
        <Countries />
      </div>
      <div className={style.mobileNotice}>
        <div className={style.mobileNoticeContent}>
          Please visit this website on a desktop browser to view and explore the
          full index details
        </div>
      </div>
    </div>
  );
};

App.displayName = "App";

export default observer(App);
