import pickBy from "lodash/pickBy";
import { makeAutoObservable } from "mobx";

class Category {
  constructor({ attributes, store }) {
    makeAutoObservable(this);

    this.store = store;
    this.set(attributes);
  }

  // Indicator name
  name = "";

  // Whether the indicator should be used in rankings
  isEnabled = true;

  setIsEnabled = (value) => {
    this.isEnabled = value;
  };

  // Child indicators
  indicators = [];

  get percentageWidth() {
    return `${(1 / this.indicators.length) * 100}%`;
  }

  set = (attributes) => {
    Object.assign(
      this,
      pickBy(attributes, (prop) => prop !== undefined)
    );
    return this;
  };

  getIndicator(indicatorId) {
    return this.indicators.find((i) => i === indicatorId);
  }
}

export default Category;
