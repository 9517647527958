import ToggleButton from "components/ToggleButton";
import style from "components/ToggleButtons.module.scss";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React from "react";

const ToggleButtons = () => {
  const { countryStore, categoryStore } = useStores();

  const disableActiveCountry = () => {
    countryStore.setActiveCountry(null);
  };

  const disableActiveCategory = () => {
    categoryStore.setActiveCategory(null);
  };

  if (!countryStore.activeCountry && !categoryStore.activeCategory) return null;

  return (
    <div className={style.root}>
      <div className={style.label}>Filters</div>
      <div className={style.buttons}>
        {countryStore.activeCountry && (
          <div className={style.button}>
            <ToggleButton
              label={countryStore.activeCountry.name}
              onClick={disableActiveCountry}
            />
          </div>
        )}
        {categoryStore.activeCategory && (
          <div className={style.button}>
            <ToggleButton
              label={categoryStore.activeCategory.name}
              onClick={disableActiveCategory}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(ToggleButtons);
