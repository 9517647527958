import classNames from "classnames";
import style from "components/CategoryRank.module.scss";
import { useStores } from "hooks/useStores";
import { getOrdinal } from "lib/helpers";
import { observer } from "mobx-react";
import React from "react";
import { Star } from "react-feather";

const CategoryRank = ({ category, country }) => {
  const { categoryStore, countryStore } = useStores();

  const countryModel = countryStore.get(country.iso);
  const categoryModel = categoryStore.get(category.id);

  const isActive =
    categoryStore.activeCategory === categoryModel &&
    countryStore.activeCountry === countryModel;

  const handleActiveToggleClick = () => {
    if (isActive) {
      categoryStore.setActiveCategory(null);
      countryStore.setActiveCountry(null);
    } else {
      categoryStore.setActiveCategory(categoryModel);
      countryStore.setActiveCountry(countryModel);
    }
  };

  const handleClick = () => {
    handleActiveToggleClick();
  };

  const isDisabled = !categoryModel.isEnabled;

  const isFirst = category.rank === 1;

  const className = classNames(style.root, style[`root___${category.id}`], {
    [style.root___isEnabled]: categoryModel.isEnabled,
    [style.root___isDisabled]: isDisabled,
    [style.root___isFirst]: isFirst,
    [style.root___isActive]: isActive,
  });

  const rankPercentage =
    ((countryStore.countries.length - category.rank) /
      countryStore.countries.length) *
    100;

  return (
    <div key={category.id} className={className}>
      <button
        className={style.toggleCountryCategory}
        onClick={handleClick}
        disabled={isDisabled}
        type="button"
      >
        <div className={style.rank}>
          <div className={style.categoryName}>{categoryModel.name}</div>
          <div className={style.rankNumber}>{category.rank}</div>
          <div className={style.rankOrdinal}>{getOrdinal(category.rank)}</div>
          <div className={style.rankTotal}>
            &nbsp;of&nbsp;{countryStore.countries.length}
          </div>
        </div>
        <div className={style.scale}>
          <div
            className={style.scaleBar}
            style={{
              width: `${rankPercentage}%`,
            }}
          />
        </div>
        {isFirst && (
          <div className={style.firstIcon}>
            <Star
              strokeWidth={0.5}
              fill="currentColor"
              stroke="currentColor"
              size={14}
            />
          </div>
        )}
      </button>
    </div>
  );
};

CategoryRank.displayName = "CategoryRank";

export default observer(CategoryRank);
