import classNames from "classnames";
import IndicatorInfo from "components/IndicatorInfo";
import style from "components/Indicators.module.scss";
import Popover from "components/Popover";
import { useStores } from "hooks/useStores";
import orderBy from "lodash/orderBy";
import { observer } from "mobx-react";
import React from "react";

const Indicators = () => {
  const { countryStore, categoryStore } = useStores();

  const { indicators } = categoryStore.activeCategory;

  const orderedIndicators = orderBy(indicators, "id");

  const className = classNames(
    style.root,
    style[`root___${categoryStore.activeCategory.id}`]
  );

  return (
    <div className={className}>
      <div className={style.header} />
      <div className={style.indicators}>
        {orderedIndicators.map((indicator) => {
          const versionIndicator = indicator[countryStore.version];

          return (
            <div key={indicator.id} className={style.indicator}>
              <div className={style.name}>{versionIndicator.name}</div>
              <div className={style.popover}>
                <Popover
                  content={<IndicatorInfo indicator={versionIndicator} />}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Indicators.displayName = "Indicators";

export default observer(Indicators);
