import classNames from "classnames";
import style from "components/SortControls.module.scss";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React from "react";
import { ArrowDown, ArrowUp, Minus } from "react-feather";

const SortControls = () => {
  const { countryStore, categoryStore } = useStores();

  const handleRankOrderDirectionToggle = () => {
    countryStore.setActiveCountry(null);
    countryStore.setOrderProperty("rank");
    countryStore.toggleOrderDirection();
  };

  const handleCategoryRankOrderDirectionToggle = () => {
    countryStore.setActiveCountry(null);
    countryStore.setOrderProperty("categoryRank");
    countryStore.toggleOrderDirection();
  };

  const DirectionIcon = observer(() => {
    if (countryStore.orderDirection === "asc")
      return <ArrowDown size={16} strokeWidth={1.5} />;
    if (countryStore.orderDirection === "desc")
      return <ArrowUp size={16} strokeWidth={1.5} />;
  });

  return (
    <div className={style.root}>
      <div className={style.control}>
        <button
          onClick={handleRankOrderDirectionToggle}
          type="button"
          className={style.orderDirectionToggle}
        >
          {countryStore.orderProperty === "rank" ? (
            <DirectionIcon />
          ) : (
            <Minus size={16} strokeWidth={1.5} />
          )}
        </button>
      </div>
      {categoryStore.activeCategory && (
        <div className={classNames(style.control, style.control___category)}>
          <button
            onClick={handleCategoryRankOrderDirectionToggle}
            type="button"
            className={style.orderPropertyToggle}
          >
            {countryStore.orderProperty === "categoryRank" ? (
              <DirectionIcon />
            ) : (
              <Minus size={16} strokeWidth={1.5} />
            )}
          </button>
        </div>
      )}
    </div>
  );
};

SortControls.displayName = "SortControls";

export default observer(SortControls);
