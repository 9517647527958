/* eslint-disable global-require */
import forEach from "lodash/forEach";
import { makeAutoObservable } from "mobx";
import Category from "models/Category";

class CategoryStore {
  constructor() {
    makeAutoObservable(this);
  }

  categories = [];

  setCategories = (categories) => {
    this.categories = categories.map(
      (category) => new Category({ attributes: category })
    );
  };

  reset() {
    this.activeCategory = null;

    this.categories.map((category) => {
      return category.setIsEnabled(true);
    });
  }

  get(id) {
    return this.categories.find((c) => c.id === id);
  }

  set() {
    const categories = require("data/categories.json");
    this.setCategories(categories.data);
  }

  // Tracks which indicator has been activated
  activeCategory = null;

  setActiveCategory = (value) => {
    this.activeCategory = value;
  };

  get enabledCount() {
    let enabledCount = 0;
    forEach(this.categories, (category) => {
      if (category.isEnabled) enabledCount += 1;
    });
    return enabledCount;
  }

  get count() {
    return this.categories.length;
  }

  get percentageWidth() {
    return `${(1 / this.count) * 100}%`;
  }

  getIndicator(categoryId, indicatorId, version) {
    return this.get(categoryId).indicators.find((i) => i.id === indicatorId)[
      version
    ];
  }

  scrollX = 0;

  setScrollX = (value) => {
    this.scrollX = value;
  };
}

export default CategoryStore;
