import "react-virtualized/styles.css";

import style from "components/Countries.module.scss";
import Country from "components/Country";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { AutoSizer, List } from "react-virtualized";

const Countries = () => {
  const { countryStore, categoryStore, uiStore } = useStores();

  const { activeCategory } = categoryStore;
  const { activeCountry } = countryStore;

  const listRef = useRef(null);

  // Order the countries by rank or category rank
  const orderedCountries =
    activeCategory && countryStore.orderProperty === "categoryRank"
      ? countryStore.byCategoryRank(activeCategory)
      : countryStore.byOverallRank;

  // Filter if a query was provided
  const filteredCountries =
    countryStore.query !== ""
      ? orderedCountries.filter((c) => {
          return c.matches(countryStore.query) ? c : null;
        })
      : orderedCountries;

  function rowRenderer({ key, index, style: rowStyle }) {
    const country = filteredCountries[index];

    return (
      <div key={key} style={rowStyle} className={style.country}>
        <Country key={country.iso} country={country} />
      </div>
    );
  }

  const getRowHeight = ({ index }) => {
    const country = filteredCountries[index];
    const isActive = activeCountry && activeCountry.iso === country.iso;

    if (isActive && !!activeCategory) {
      return uiStore.isSmallerThanTablet ? 520 : 240;
    }

    if (isActive) {
      return uiStore.isSmallerThanTablet ? 520 : 960;
    }

    return 75;
  };

  useEffect(() => {
    if (listRef.current) listRef.current.recomputeRowHeights();
  }, [activeCountry, activeCategory]);

  if (filteredCountries.length === 0) return null;

  return (
    <div className={style.root}>
      <AutoSizer className={style.container}>
        {({ height, width }) => (
          <List
            height={height}
            width={width}
            estimatedRowSize={100}
            rowHeight={getRowHeight}
            rowRenderer={rowRenderer}
            rowCount={filteredCountries.length}
            ref={listRef}
            className={style.list}
          />
        )}
      </AutoSizer>
    </div>
  );
};

Countries.displayName = "Countries";

export default observer(Countries);
