import camelcaseKeys from "camelcase-keys-deep";
import { matches } from "lodash";
import forEach from "lodash/forEach";
import indexOf from "lodash/indexOf";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";
import { makeAutoObservable } from "mobx";
import Country from "models/Country";

class CountryStore {
  constructor({ categoryStore }) {
    makeAutoObservable(this);

    this.categoryStore = categoryStore;
  }

  versions = ["1.1", "1.2", "1.3", "1.4", "1.5"];

  version = this.versions[this.versions.length - 1]; // last one is the default

  setVersion = (value) => (this.version = value);

  countries = [];

  setCountries = (countries) =>
    (this.countries = countries.map(
      (country) => new Country({ attributes: country, store: this })
    ));

  set = async () => {
    const countriesData = await fetch(`data/${this.version}.json`);

    const response = await countriesData.json();

    const countries = camelcaseKeys(response, {
      deep: true,
    });

    this.setCountries(countries);

    // Calculate the ranks for the first time
    this.calculateRanks();
  };

  // Tracks which country has been activated
  activeCountry = null;

  setActiveCountry = (value) => {
    this.activeCountry = value;
  };

  reset = () => {
    this.activeCountry = null;
  };

  get(iso) {
    return this.countries.find((c) => c.iso === iso);
  }

  // Filtering

  query = "";

  setQuery = (value) => {
    this.query = value;
  };

  get filteredCountries() {
    if (this.query === "") return this.countries;

    return this.countries.filter(() => matches(this.query));
  }

  // Ordering

  orderDirection = "asc";

  toggleOrderDirection = () =>
    (this.orderDirection = this.orderDirection === "asc" ? "desc" : "asc");

  orderProperty = "rank"; // rank, categoryRank

  setOrderProperty = (value) => {
    this.orderProperty = value;
  };

  toggleOrderProperty = () =>
    (this.orderProperty =
      this.orderProperty === "rank" ? "categoryRank" : "rank");

  get byOverallRank() {
    return orderBy(this.countries, "rank", this.orderDirection);
  }

  byCategoryRank(category) {
    return orderBy(
      this.countries,
      (country) => country.getCategoryRank(category),
      this.orderDirection
    );
  }

  // Calculation

  handleTies() {
    // Update the count
    this.handleTiesCount += 1;
    // Set previous country indicator
    let previousCountry = null;

    // Loop through the countries ordered by rank
    forEach(this.byOverallRank, (country) => {
      // if we have a tie...
      if (previousCountry && country.rank === previousCountry.rank) {
        // If the current country's lowest is more than the previous, bump it up
        if (country.lowestRank > previousCountry.lowestRank) {
          const rank = country.rank + 1;
          country.set({ rank });
        } else {
          const rank = previousCountry.rank + 1;
          previousCountry.set({ rank });
        }

        // Recursively start again
        this.handleTies();
      }

      // Set this country to be the previous
      previousCountry = country;
    });
  }

  isCalculating = false;

  calculateRanks() {
    const rankMeans = [];

    // Loop through each country
    forEach(this.countries, (country, i) => {
      let rankSum = 0;
      let rankMean = 0;

      // Loop through indicators
      forEach(country.categories, (countryCategory) => {
        // Get this category
        const category = this.categoryStore.categories.find(
          (c) => c.id === countryCategory.id
        );
        // Make sure indicator is active, and add to rank sum
        if (category.isEnabled) rankSum += countryCategory.rank;
      });
      // Divide rank sum by number of active indicators
      rankMean = rankSum / this.categoryStore.enabledCount;
      // Set the rank mean on this country
      country.set({ rankMean });
      // Cache to array
      rankMeans[i] = rankMean;

      return rankMean;
    });

    // Create a sorted list of means
    const rankMeansSorted = sortBy(rankMeans);

    // Set the countries rank
    this.countries.forEach((country) => {
      const rank = indexOf(rankMeansSorted, country.rankMean) + 1;
      country.set({ rank });
    });

    // Now handle ties
    this.handleTies();
  }

  scrollX = 0;

  setScrollX = (value) => {
    this.scrollX = value;
  };
}

export default CountryStore;
