export const ITEMS = [
  {
    label: "About",
    href: "https://www.goodcountry.org/index/about-the-index/",
    tooltip: `The Good Country Index measures what countries contribute to the world outside their own borders, and what they take away: it’s their balance-sheet towards humanity and the planet`,
  },
  {
    label: "Source Data",
    href: "https://www.goodcountry.org/index/source-data/",
  },
  {
    label: "FAQs",
    href: "https://www.goodcountry.org/index/your-questions/",
  },
];
