import style from "components/Navigation.module.scss";
import { ITEMS } from "lib/nav";
import React from "react";

import Tippy from "@tippyjs/react";

const Navigation = () => {
  return (
    <div className={style.root}>
      <div className={style.items}>
        {ITEMS.map((item) => {
          return (
            <_Item key={item.label} tooltip={item.tooltip}>
              <a
                href={item.href}
                className={style.item}
                target="_blank"
                rel="noreferrer"
              >
                <div className={style.label}>{item.label}</div>
              </a>
            </_Item>
          );
        })}
      </div>
    </div>
  );
};

const _Item = ({ tooltip, children }) => {
  if (tooltip) {
    return (
      <Tippy
        content={<div className={style.tooltip}>{tooltip}</div>}
        theme="light-border"
        interactive
        appendTo={() => document.body}
      >
        {children}
      </Tippy>
    );
  }

  return children;
};

export default Navigation;
