import CategoryRank from "components/CategoryRank";
import style from "components/CategoryRanks.module.scss";
import { observer } from "mobx-react";
import React from "react";

const CategoryRanks = ({ country }) => {
  return (
    <div className={style.root}>
      {country.categories.map((category) => (
        <CategoryRank key={category.id} category={category} country={country} />
      ))}
    </div>
  );
};

CategoryRanks.displayName = "CategoryRanks";

export default observer(CategoryRanks);
